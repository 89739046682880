import React from "react";
import { Reset2fa } from "csdm-idp-frontend";
import wallpaper from "../../assets/images/background.jpg";

const LOGIN_URL = `${window.location.origin}/login`;

const Reset2faView = props => {
  return (
    <Reset2fa
      language="gb"
      background={wallpaper}
      token={props.match.params.index}
      LOGIN_URL={LOGIN_URL}
    />
  );
};
export default Reset2faView;
