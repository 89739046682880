import React from 'react'
import PropTypes from 'prop-types'
import { UserManagement } from 'csdm-idp-frontend'

const UserManagementView = props => (
  <div className='vertical one csdm__card__bottom__right' style={{ overflowY: 'auto'}}>
    <div className="ssp_page_title">User Management</div>
    <UserManagement
      isAuthenticated={props.isAuthenticated}
    />
  </div>
)

export default UserManagementView

UserManagementView.contextTypes = {
  router: PropTypes.object,
}

UserManagementView.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}
