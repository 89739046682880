import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Modal, Avatar, Icon } from 'antd';
import PropTypes from 'prop-types';
import { ClientIDP, updateTokenActiveOrganization, getTokenInformation } from 'csdm-idp-frontend';
import { withRouter } from 'react-router-dom';
import {
  ROUTE_LOGOUT,
  getLink,
  ROUTE_USER_MANAGEMENT,
  routeAllowed,
  ROUTE_USER_MYINFO
} from '../../routes';

const { confirm } = Modal;
const DEFAULT_ORGANISATION_NAME = 'default org';

class UserMenu extends Component {
  state = {
    activeOrganisation: 'default org',
    loading: false,
    organizations: [],
  }

  componentDidMount() {
    this.setActiveOrganisationOfUser()
  }

  componentWillUnmount() {
    // Assign this.setState to an empty function to avoid console warnings
    // Warning: Can't call setState (or forceUpdate) on an unmounted component.
    this.setState = () => undefined
  }

  // Get the token and check which organisation is active.
  setActiveOrganisationOfUser = () => {
    const tokenInfo = getTokenInformation()
    const activeOrganisation = tokenInfo.organizations.find((org) => org.active);
    const organisationName = activeOrganisation
      ? activeOrganisation.name
      : DEFAULT_ORGANISATION_NAME;
    this.setState({ activeOrganisation: organisationName });
    this.handleGetOrganizations();
  }
  
  /*
   * Gets organizations connected to the user.
   * Also then sets the data to the organization select menu.
   */
  handleGetOrganizations = () => {
    ClientIDP.query('organizationsByUser')
      .then((response) => {
        const organizations = response.viewer.organizationsByUser;
        this.setState({ organizations });
      })
      .catch((error) => {
        console.error('Error fetching organizations:', error);
      });
  };

  /*
  * When the user clicks on a different organisation the token will be updated.
  * The page must reload because selected organisation data must be fetched with the renewed token.
  */
  handleMenuClickOrganisation = (e) => {
    this.setState({ loading: true })
    updateTokenActiveOrganization(e.key)
      .then(result => {
        this.setState({ loading: false })
        window.location.href = window.location.origin;
      }).catch(err => {
        this.setState({ loading: false })
      })
  }

  logout = () => {
    confirm({
      title: 'Are you sure you want to logout?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const { history } = this.props;
        history.push(getLink(ROUTE_LOGOUT));
      }
    })
  }

  onUserMenuClick = ({ key }) => {
    const { history } = this.props;
    if (key === 'myAccount') {
      history.push(getLink(ROUTE_USER_MYINFO))
    } else if (key === 'userManagement') {
      history.push(getLink(ROUTE_USER_MANAGEMENT))
    } 
    else if (key === 'logout') {
      this.logout()
    }
  }

  /*
  * If the user has the right to see multiple organisations, create a menu.
  * If not, just show the current organisation.
  */
  getUserOrganisationsMenu = () => {
    const { loading, activeOrganisation, organizations } = this.state;
    if (!(organizations.length > 1)) {
      return (
        <span>{activeOrganisation}
          <Icon type="global" style={{ marginLeft: 5 }} />
        </span>
      )
    }
    organizations.sort((a,b) => a.name.localeCompare(b.name))
    const isLoadingIconType = loading ? 'loading' : 'down'
    const menu = (
      <Menu onClick={this.handleMenuClickOrganisation}>
        {organizations.map(organization =>
          <Menu.Item key={organization.name}>{organization.name}</Menu.Item>
        )}
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <span style={{ display: 'inline-block' }} >
          {activeOrganisation}
          <Icon type={isLoadingIconType} style={{ marginLeft: 5 }} />
        </span>
      </Dropdown>
    )
  }

  render() {
    const { user } = this.props;
    const menuOrganisation = this.getUserOrganisationsMenu();
    const menuUser = (
      <Menu onClick={this.onUserMenuClick} >
        <Menu.Item key="myAccount">
          <Icon type="user" />Profile
        </Menu.Item>
        {routeAllowed(ROUTE_USER_MANAGEMENT.path) &&
          <Menu.Item key="userManagement">
            <Icon type="team" />User management
          </Menu.Item>
        } 
        {routeAllowed(ROUTE_USER_MANAGEMENT.path) &&
          <Menu.Divider />
        }
        <Menu.Divider />
        <Menu.Item key="logout">
          <Icon type="logout" />Logout
        </Menu.Item>
      </Menu>
    );
    // Hide the organisation menu and the full username if the screen is too small and the menu is not collapsed.
    const isMobileAndMenuIsNotCollapsed = !this.props.collapsed && window.outerWidth < 768

    return (
      <div style={{ marginRight: 20, float: 'right', fontSize: 16, color: 'rgba(0, 0, 0, 0.65)'}} >
        {!isMobileAndMenuIsNotCollapsed &&
          <span style={{ marginRight: 20 }} >
            {menuOrganisation}
          </span>
        }
        <Dropdown overlay={menuUser} trigger={['click']}>
          <span style={{ display: 'inline-block' }}>
            {!isMobileAndMenuIsNotCollapsed && `${user.firstName} ${user.lastName}`}
            <Avatar style={{ marginLeft: 5 }} >
              {`${user.firstName.substring(0, 1).toUpperCase()}${user.lastName.substring(0, 1).toUpperCase()}`}
            </Avatar>
          </span>
        </Dropdown>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user
})

export default connect(mapStateToProps)(withRouter(UserMenu));

UserMenu.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    organizations: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  collapsed: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};
