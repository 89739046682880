import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { BrowserRouter as Router } from 'react-router-dom'
import { initialize } from 'csdm-idp-frontend'
import { initializeCmdb } from 'csdm-cmdb-frontend'
import SiderMenu from './SiderMenu'
import UserMenu from './containers/usermenu'
import { validateSession } from './actions/session'
import CustomSpinner from './components/CustomSpinner'
import Routes from './routes'
import './assets/styles/antd.less';
import './assets/styles/style.css'
import { version, name } from '../package.json'
import './styles/index.css'
import './styles/components/index.scss'
import moment from 'moment'

const { Header } = Layout

//Print version in console
console.log(`${name} version: ${version}`);

// Environment variables
const FETCH_URI = window._env_.OOP_ENDPOINT;

const environment = window._env_.ENVIRONMENT || 'development';
console.log('IDP set initialize', environment)
const idpEndpoint = window._env_.IDP_ENDPOINT || null;
const idpSession = { applicationName: ['SHELL-PORTAL'], environment, endpoint: idpEndpoint }
initialize(idpSession)
const cmdbSession = { environment }
initializeCmdb(cmdbSession)

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false
    }
    this.props.dispatch(validateSession());
    moment.updateLocale('en', { week: { dow: 1 } }); // Set the first day of the week to monday.
  }

  /**
   * Sets the context for all the
   * child components
   */
  getChildContext() {
    return {
      uris: FETCH_URI,
    }
  }

  collapseMenu = (collapsed) => {
    this.setState({ collapsed })
  }

  render() {
    if (this.props.loading) {
      return (
        <CustomSpinner
          color1="#3ea3d9" // Shell yellow
          color2="#9dd0ed"
          color3="#d8ecf8"
          size="6%"
        />
      )
    }

    const childProps = {
      isAuthenticated: this.props.isAuthenticated,
      loading: this.props.loading,
    }

    return (
      <Router>
        <Layout style={{ height: '100%', background: '#ececec', padding: 0, margin: 0 }} >
          { this.props.isAuthenticated &&
            <SiderMenu
              collapsed={this.state.collapsed}
              collapseMenu={this.collapseMenu}
            />
          }
          <Layout style={{ background: '#ececec', padding: 0, margin: 0 }} >
            { this.props.isAuthenticated &&
              <Header
                style={{
                  background: '#fff',
                  padding: 0,
                  margin: '8px 8px 4px 4px',
                  boxShadow: 'rgb(221, 225, 230) 0px 0px 5px 2px',
                }}
              >
                <UserMenu collapsed={this.state.collapsed} />
              </Header>
              }
              <div style={{ display: 'flex', flex: 1, overflowY: 'auto'}} >
                  <Routes childProps={childProps} />
              </div>
          </Layout>
        </Layout>
      </Router>
    )
  }
}

App.childContextTypes = {
  uris: PropTypes.string, // Make the URI for available in the app context
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({}),
}
App.defaultProps = {
  user: null,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.session.isAuthenticated,
    loading: state.session.loading,
    user: state.session.user,
  }
}

export default connect(mapStateToProps)(App)
