import React from 'react'
import PropTypes from 'prop-types'
import { MyAccount } from 'csdm-idp-frontend'
import { Row } from 'antd'

const UserMyInfoView = () => (
  <div className="one csdm__card__bottom__right">
    <Row
      style={{ borderBottom: '1px solid #d9d9d9' }}
      type="flex"
      align="middle"
    >
      <div className="ssp_page_title">My account</div>
    </Row>
    <div style={{ margin: 40 }} >
      <MyAccount
        formItemLayout={{
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 8 },
          },
        }}
      />
    </div>
  </div>
)

export default UserMyInfoView

UserMyInfoView.contextTypes = {
  router: PropTypes.object,
}
